import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Layout, Listing, Wrapper, SliceZone, Title, SEO, Header } from '../components'
import website from '../../config/website'

const Hero = styled.header`c
  background-color: ${props => props.theme.colors.greyLight};
  padding-top: 0;
  padding-bottom: 0;
`

const Headline = styled.p`
  font-family: 'Source Sans Pro', -apple-system, 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  color: ${props => props.theme.colors.grey};
  font-size: 1.25rem;
  a {
    font-style: normal;
    font-weight: normal;
  }
`

const Post = ({ data: { prismicPost, posts }, location }) => {
  const { data } = prismicPost

  console.log(data)
  return (
    <Layout customSEO>
      <SEO
        title={`${data.title.text} | ${website.titleAlt}`}
        pathname={location.pathname}
        desc={data.description}
        node={prismicPost}
        article
      />
      <Hero>
        <Header title={data.title.text} bg={data.header_image.url} postsFromPost={posts} />
      </Hero>

      <SliceZone id={website.skipNavId} allSlices={data.body} />
    </Layout>
  )
}

export default Post

Post.propTypes = {
  data: PropTypes.shape({
    prismicPost: PropTypes.object.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

// The typenames come from the slice names
// If this doesn't work for you query for __typename in body {} and GraphiQL will show them to you

export const pageQuery = graphql`
  query PostBySlug($uid: String!) {
    prismicPost(uid: { eq: $uid }) {
      uid
      first_publication_date
      last_publication_date
      data {
        title {
          text
        }
        header_image {
          url
        }
        description
        date(formatString: "DD.MM.YYYY")
        
        body {
          ... on PrismicPostBodyText {
            slice_type
            id
            primary {
              section_title {
                 text
              }
              section_text{
                type
                oembed {
                  account_type
                  author_name
                  author_url
                  description
                  embed_url
                  duration
                  html
                  height
                  is_plus
                  provider_name
                  provider_url
                  thumbnail_height
                  thumbnail_url
                  thumbnail_url_with_play_button
                  thumbnail_width
                  title
                  type
                  uri
                  version
                  video_id
                  width
                }
              }
            }
          }
          ... on PrismicPostBodyImage {
            slice_type
            id
            primary {
              caption
              max_width_percentage
              position
              image {
                url
              }
            }
          }
          ... on PrismicPostBodyText1 {
            slice_type
            id
            primary {
              title1 {
                 text
              }
               content {
                raw{
                type
                text
                }
                 text
                 html
              }
            }
          }
          ... on PrismicPostBodyImageCarousel{
            slice_type
             primary {
              title2
              }
            items{
               image{
               url
               }
               caption
             }
          }
        }
      }
    }
    posts: allPrismicPost(sort: { fields: [data___date], order: DESC }) {
      edges {
        node {
          uid
          data {
            title {
              text
            }
            date(formatString: "DD.MM.YYYY")
            
          }
        }
      }
    }
  }
`
